<template>
  <div class="mm">
    <el-row>
      <el-col :span="24">
        <el-tabs v-model="activeNav">
          <el-tab-pane
            :label="item.title"
            :name="item.type"
            v-for="(item, index) in navs"
            :key="index"
            :disabled="item.disabled"
          ></el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <div class="line"></div>
    <div class="main">
      <component @refresh="refresh" :is="comName" :info="info"></component>
    </div>
  </div>
</template>

<script>
import companyInfo from "../components/companyInfo.vue"; //公司信息
import basicData from "../components/basicData.vue"; //个人信息
import businessData from "../components/businessData.vue"; //开票信息
import receivingAddress from "../components/receivingAddress.vue"; //收货信息
import thinkBuyGoods from "../components/thinkBuyGoods.vue"; //所买商品
import thinkSellGoods from "../components/thinkSellGoods.vue"; //所卖商品
import changeMain from "../components/changeMain.vue"; //转移权限

import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      navs: [
        { title: "个人信息", type: "1", name: "basicData" },
        { title: "公司信息", type: "2", name: "companyInfo" },
        { title: "开票信息", type: "3", name: "businessData" },
        { title: "收货信息", type: "5", name: "receivingAddress" },
        { title: "所买商品", type: "6", name: "thinkBuyGoods" },
        { title: "所卖商品", type: "7", name: "thinkSellGoods" },
        // {
        //   title: "员工列表",
        //   type: "8",
        //   name: "changeMain",
        //   disabled: this.contactPersonFlag == "1",
        // },
      ],
      //  this.info.contactPersonFlag == '1'
      activeNav: "1", //当前选中的导航类型
      info: {}, //用户信息
      contactPersonFlag: "1",
    };
  },
  components: {
    companyInfo,
    basicData,
    businessData,
    receivingAddress,
    thinkBuyGoods,
    thinkSellGoods,
    changeMain,
  },
  methods: {
    ...mapActions("myMessageModule", [
      "getUser", //获取客户详情
      "getEnterprise",
    ]),
    getDetails() {
      //获取详情信息
      this.getUser().then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          this.info = data;
		   this.getEnterprise(this.info.enterpriseId).then((res) => {
        let { code, data } = res.data;
        this.contactPersonFlag = data.contactPersonFlag;
		if(data.contactPersonFlag=='1'){
			this.navs.push({
          title: "员工列表",
          type: "8",
          name: "changeMain",
        })
		}
      });
        }
      });
	  
     
    },
    refresh() {
      this.getDetails();
    },
  },
  created() {
    this.getDetails();
  },
  computed: {
    ...mapState([
      "userInfo", //用户登录信息
    ]),
    comName() {
      return this.navs.filter((v) => v.type === this.activeNav)[0].name;
    },
  },
};
</script>

<style scoped lang="less">
.mm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .main {
    flex: 1;
    overflow-y: auto;
  }
  .line {
    height: 10px;
    background: #f2f5fa;
    width: calc(100% + 20px);
    position: relative;
    left: -10px;
  }
}
</style>
