<template>
	<div class="c">
		<div class="c-top" style="padding-bottom: 10px;">
			<el-button
				size="mini" 
				type="primary" 
				@click="addGoods">新增</el-button>
		</div>
		<lida-table
			:cutHeight="0" 
			:data="data" 
			border 
			:page="page"
			:pageSize="pageSize"
			@change="pageChange"
			:total="total"
			ref="table">
			<el-table-column type="index" label="序号" width="55" fixed/>
			<lida-table-column label="产品信息">
				<template #default="scope">
					<div class="g-info">
						<div class="g-icon">
							<img :src="scope.row.src"/>
						</div>
						<div class="g-right">
							<div class="g-r-title">
								{{scope.row.productName}}
								<div class="label" :title="scope.row.brandName">{{scope.row.brandName}}</div>
							</div>
							<div class="g-r-data">
								{{scope.row.barCode || '无'}}
								<span>|</span>
								{{scope.row.seriesName || '无'}}
								<span>|</span>
								{{scope.row.skuNumber || '无'}}
							</div>
						</div>
					</div>
				</template>
			</lida-table-column>
			<lida-table-column label="订货号" prop="articleNo" />
			<lida-table-column label="面价" prop="guidePrice" />
			<lida-table-column label="操作" fixed="right">
				<template #default="scope">
					<el-button size="mini" @click="deleteRow(scope.row)" type="text">删除</el-button>
				</template>
			</lida-table-column>
		</lida-table>
	</div>
	
	<!-- 添加商品弹框 -->
	<add-goods-dialog 
		@success="addSuccess"
		v-model="show" />
		
</template>

<script>
	import addGoodsDialog from './dialog/addGoods.vue'  //添加商品弹框
	import { mapActions } from 'vuex'
	import { ElMessageBox, ElMessage } from 'element-plus'
	export default{
		data(){
			return{
				show:false,
				page:1,
				pageSize:10,
				total:0,
				data:[],  //列表数据
			}
		},
		components:{
			addGoodsDialog,
		},
		methods:{
			...mapActions('myMessageModule',[
				'userPageEmption',  //获取我的买卖商品
				'userDelEmption',  //删除我的买卖商品
			]),
			addGoods(){
				this.show = true;
			},
			addSuccess(){ //添加成功的回调
				this.getList();
			},
			getList(){
				this.userPageEmption({
					pageNo:this.page,
					pageSize:this.pageSize,
					type:'1',
				}).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						//每次搜索结果数据累加
						this.data = data.rows;
						this.total = data.totalCount;
					}
				})
			},
			pageChange(page,pageSize){ //获取列表数据
				this.page = page;
				this.pageSize = pageSize;
				this.getList();
			},
			deleteRow(row){
				console.log(row);
				ElMessageBox.confirm('是否删除该商品?','提示',{
				  confirmButtonText: '确认',
				  cancelButtonText: '取消',
				  type: 'warning',
				}).then(() => {
				  this.userDelEmption(row.id).then(res => {
					  let {code,data} = res.data;
					  if(code === '0'){
						  ElMessage({type: 'success',message: '删除成功',})
						  this.getList();  //获取列表
					  }
				  })
				}).catch(() => {})
			}
		},
		created(){
			this.getList();
		}
	}
</script>

<style scoped lang="less">
	@import "../style/index.less";
</style>
