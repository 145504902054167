<template>
  <div class="c">
    <div class="c-top">
      <el-button size="mini" type="primary" @click="changeEdit" v-if="!isEdit"
        >编辑</el-button
      >
    </div>
    <div class="c-title">
      <div class="c-t-span">基础信息</div>
    </div>
    <el-form label-width="120px" :model="obj" :rules="rules" ref="form">
      <el-row>
        <el-col :span="7">
          <el-form-item label="用户id:">
            <el-input v-model="obj.customerNumber" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="用户类型:">
            <el-radio-group v-model="obj.userType" disabled>
              <el-radio
                :label="item.type"
                :key="item.type"
                v-for="item in userTypes"
                >{{ item.title }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="我的名称:" prop="realName">
            <el-input
              placeholder="请输入"
              v-model="obj.realName"
              :disabled="!isEdit"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="电话:" prop="mobilePhone">
            <el-input
              placeholder="请输入"
              v-model="obj.mobilePhone"
              :disabled="!isEdit"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="月采购量" prop="monthlyPurchaseAmount">
            <el-select
              v-model="obj.monthlyPurchaseAmount"
              placeholder="请选择"
              :disabled="!isEdit"
            >
              <el-option
                :label="item.title"
                :value="item.type"
                v-for="(item, index) in purchaseQuantity"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="身份">
            <el-radio-group :disabled="true" v-model="obj.identifyLabel">
              <el-radio
                :label="item.type"
                :key="item.type"
                v-for="item in identifyTypeOptions"
                >{{ item.title }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-show="obj.identifyLabel !== '0'">
        <el-col :span="24">
          <el-form-item label="身份证:">
            <upload-file
              isType="add"
              list-type="picture-card"
              v-model="obj.idCardFiles"
              :limit="1"
              :disabled="!isEdit"
              :list="obj.idCardFilesList"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-show="obj.identifyLabel !== '0'">
        <el-col :span="24">
          <el-form-item label="银行卡:">
            <upload-file
              isType="add"
              list-type="picture-card"
              v-model="obj.bankFiles"
              :limit="1"
              :disabled="!isEdit"
              :list="obj.bankFilesList"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="obj.identifyLabel !== '0'">
        <el-col :span="7">
          <el-form-item label="实际住址:" prop="address">
            <el-input
              placeholder="请输入"
              v-model="obj.address"
              :disabled="!isEdit"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="7" v-if="obj.identifyLabel === '0'">
          <el-form-item label="身份证号:" prop="idCard">
            <el-input
              placeholder="请输入"
              v-model="obj.idCard"
              :disabled="!isEdit"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="7" v-if="obj.identifyLabel === '0'">
          <el-form-item label="年龄:" prop="age">
            <el-input
              placeholder="请输入"
              v-model="obj.age"
              :disabled="!isEdit"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="7" v-if="obj.identifyLabel === '0'">
          <el-form-item label="工作年限:" prop="yearOfWork">
            <el-input
              placeholder="请输入"
              v-model="obj.yearOfWork"
              :disabled="!isEdit"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="7" v-if="obj.identifyLabel === '0'">
          <el-form-item label="工牌号码:" prop="yearOfWork">
            <el-input
              placeholder="请输入"
              v-model="obj.labourCardNumber"
              :disabled="!isEdit"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7" v-if="obj.identifyLabel === '0'">
          <el-form-item label="等级编号:" prop="classifiedId">
            <tree-select
              placeholder="请选择等级编号"
              v-model="obj.classifiedId"
              :clearable="false"
              :options="classifiedId"
              :disable-branch-nodes="true"
              :searchable="true"
              :defaultExpandLevel="10"
              :disabled="!isEdit"
              :flatten-search-results="true"
            />
          </el-form-item>
        </el-col>

        <el-col :span="7" v-if="obj.identifyLabel === '0'">
          <el-form-item label="职位:" prop="jobPost">
            <el-input
              placeholder="请输入"
              v-model="obj.jobPost"
              :disabled="!isEdit"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="7" v-if="obj.identifyLabel === '0'">
          <el-form-item label="角色:" prop="jobRole">
            <el-input
              placeholder="请输入"
              v-model="obj.jobRole"
              :disabled="!isEdit"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="c-title">
        <div class="c-t-span">邀请信息</div>
      </div>
      <el-row v-if="obj.identifyLabel == '1'">
        <el-col :span="8" style="marginLeft:7%">
          分享链接：分享给同行采购（非代理、分销商）介绍他们用盘雷采购；<br />
          同行采购一定金额后，得100元一家（具体可以了解规则）；<br />
          邀请超过100家，还可以成为平台兼职，边工作，边挣钱；具体联系平台，联系注明“兼职”。
        </el-col>
        <el-col :span="8" style="marginLeft:16%">
          通过发送此邀请链接或询价链接，注册用户都会绑定成为你的供应商。如果你的供应商，是第一次注册。介绍他上传库存或在售的产品<br />
          1.方便你的采购 2. 整个平台采购<br />
          假如整个平台有公司采购，你将收到平台“供应商开发”奖励或者直接申请成为盘雷“供应商开发”人员，管理供应商资源共享工业互联网平台福利，拿取巨额收入。<br />
        </el-col>
      </el-row>
      <el-row v-if="obj.identifyLabel == '0'">
        <el-col
          :span="8"
          style="marginLeft:5%"
          v-if="inviteDisplay.INVITE_CUSTOMER_LINK_ADMIN === '1'"
        >
          此邀请接为直接客户（请勿发送给同行代理商，分销商，可买，可卖公司)邀请客户入驻,开启行业互联网时代<br />
          1.客户24小时向你发起询价，你可以一链向全平台询价，得出结果。加利润发给客户。<br />
          2.你可以销售全品牌,货源由平台提供<br />
          3.报价,合同，付款,发货，一键完成。轻松管理客户<br />
          4.成为工业行业买卖独立工作职业者<br />
        </el-col>
        <el-col
          :span="8"
          :style="{
            marginLeft:
              inviteDisplay.INVITE_SUPPLIER_LINK_ADMIN === '1' &&
              inviteDisplay.INVITE_CUSTOMER_LINK_ADMIN === '1'
                ? '16%'
                : '5%',
          }"
          v-if="inviteDisplay.INVITE_SUPPLIER_LINK_ADMIN === '1'"
        >
          通过发送此邀请链接或询价链接，注册用户都会绑定成为你的供应商。如果你的供应商，是第一次注册。介绍他上传库存或在售的产品<br />
          1.方便你的采购 2. 整个平台采购<br />
          假如整个平台有公司采购，你将收到平台“供应商开发”奖励或者直接申请成为盘雷“供应商开发”人员，管理供应商资源共享工业互联网平台福利，拿取巨额收入。
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" v-if="obj.identifyLabel == '1'">
          <el-form-item label="分享链接:">
            <div class="c-i-bottom">
              <el-input
                v-model="getLinkUrl1"
                style="width: 500px;"
                placeholder="请输入"
                disabled
              ></el-input>
              <el-button
                type="primary"
                size="mini"
                style="margin-left: 10px;"
                @click="copyVal"
                >复制</el-button
              >
            </div>
          </el-form-item>
        </el-col>
        <el-col
          :span="12"
          v-if="
            obj.identifyLabel == '0' &&
              inviteDisplay.INVITE_CUSTOMER_LINK_ADMIN === '1'
          "
        >
          <el-form-item label="客户分享链接:">
            <div class="c-i-bottom">
              <el-input
                v-model="getLinkUrl"
                style="width: 500px;"
                placeholder="请输入"
                disabled
              ></el-input>
              <el-button
                type="primary"
                size="mini"
                style="margin-left: 10px;"
                @click="copyVal"
                >复制</el-button
              >
            </div>
          </el-form-item>
        </el-col>
        <el-col
          :span="12"
          v-if="inviteDisplay.INVITE_SUPPLIER_LINK_ADMIN === '1'"
        >
          <el-form-item label="供应商邀请地址:">
            <div class="c-i-bottom">
              <el-input
                v-model="getLinkUrl2"
                style="width: 500px;"
                placeholder="请输入"
                disabled
              ></el-input>
              <el-button
                type="primary"
                size="mini"
                style="margin-left: 10px;"
                @click="copyVal2"
                >复制</el-button
              >
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="c-footer" v-if="isEdit">
      <el-button
        type="primary"
        size="mini"
        style="margin-right: 20px;"
        @click="confirm"
        >确认</el-button
      >
      <el-button size="mini" style="margin-left: 20px;" @click="cancel"
        >取消</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { copyText } from "@/common/js/common.js";
import { ElMessage } from "element-plus";
import uploadFile from "@/components/publicComponent/uploadFile.vue";
export default {
  data() {
    return {
      inviteDisplay: {},
      isEdit: false,
      obj: {
        identifyLabel: "1",
        bankFilesList: [],
        idCardFilesList: [],
      },
      rules: {
        monthlyPurchaseAmount: [
          { required: true, message: "请选择月采购量", trigger: "change" },
        ],
        realName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        mobilePhone: [
          { required: true, message: "请输入电话", trigger: "blur" },
        ],
        identifyLabel: [
          { required: true, message: "请选择身份", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入实际地址", trigger: "blur" },
        ],
        idCard: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
        ],
        age: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        yearOfWork: [
          { required: true, message: "请输入工作年限", trigger: "blur" },
        ],
        labourCardNumber: [
          { required: true, message: "请输入工牌号码", trigger: "blur" },
        ],
        classifiedId: [
          { required: true, message: "请输入等级编号", trigger: "change" },
        ],
        jobPost: [{ required: true, message: "请输入职位", trigger: "blur" }],
        jobRole: [{ required: true, message: "请输入角色", trigger: "blur" }],
      },
    };
  },
  props: ["info"],
  components: {
    uploadFile,
  },
  computed: {
    ...mapState(["userInfo"]),
    ...mapState("myMessageModule", [
      "userTypes", //用户类型
      "purchaseQuantity", //月采购量
      "identifyTypeOptions", // wyb 身份选项
      "classifiedId", //等级编号
    ]),

    /** 客户邀请地址 */
    getLinkUrl() {
      return (
        window.location.protocol +
        "//" +
        window.location.host +
        "/login/index?uid=" +
        this.userInfo.userId +
        "&belongType=1"
      );
    },
    /** 客户的邀请地址 */
    getLinkUrl1() {
      return (
        window.location.protocol +
        "//" +
        window.location.host +
        "/login/index?inviteUserId=" +
        this.obj.inviteUserId +
        "&belongType=1"
      );
    },
    /** 供应商邀请弟子 */
    getLinkUrl2() {
      return (
        window.location.protocol +
        "//" +
        window.location.host +
        "/login/index?uid=" +
        this.userInfo.userId +
        "&belongType=0"
      );
    },
  },
  methods: {
    ...mapActions("myMessageModule", [
      "userEditUser", //修改用户特别信息
    ]),
    ...mapActions("systemModule", ["getDisplayTabListOfHome"]),
    changeEdit() {
      //编辑的点击事件
      this.isEdit = true;
    },
    cancel() {
      this.isEdit = false;
    },
    confirm() {
      //确认的点击事件
      this.$refs.form.validate((valid) => {
        if (valid) {
          var data = JSON.parse(JSON.stringify(this.obj));
          data.idCardFiles = data.idCardFiles.length ? data.idCardFiles[0] : "";
          data.bankFiles = data.bankFiles.length ? data.bankFiles[0] : "";
          this.userEditUser(data).then((res) => {
            let { code, data } = res.data;
            if (code === "0") {
              ElMessage({
                type: "success",
                message: "修改成功",
              });
              this.$emit("refresh"); //刷新详情
            }
          });
        }
      });
    },
    copyVal() {
      //复制
      copyText(this.getLinkUrl);
    },
    copyVal2() {
      //复制
      copyText(this.getLinkUrl2);
    },
    async init() {
      if (this.info) {
        var info = JSON.parse(JSON.stringify(this.info));
        info.bankFilesList = JSON.parse(info.bankFiles || "[]");
        info.idCardFilesList = JSON.parse(info.idCardFiles || "[]");
        info.bankFiles = [];
        info.idCardFiles = [];
        this.obj = {
          ...info,
        };
      }
    },
  },
  async created() {
    this.init();

    try {
      const { userId } = JSON.parse(localStorage.userInfo);
      const INVITE_CUSTOMER_DATA = await this.getDisplayTabListOfHome({
        setTpCd: "INVITE_CUSTOMER_LINK_ADMIN",
        sellerId: userId,
      });
      const INVITE_SUPPLIER_DATA = await this.getDisplayTabListOfHome({
        setTpCd: "INVITE_SUPPLIER_LINK_ADMIN",
        sellerId: userId,
      });
      if (
        INVITE_CUSTOMER_DATA?.data?.data &&
        INVITE_SUPPLIER_DATA?.data?.data
      ) {
        this.inviteDisplay = {
          INVITE_CUSTOMER_LINK_ADMIN: INVITE_CUSTOMER_DATA.data.data.value,
          INVITE_SUPPLIER_LINK_ADMIN: INVITE_SUPPLIER_DATA.data.data.value,
        };
      }
    } catch (error) {}
  },
  watch: {
    info(v) {
      this.init();
    },
  },
};
</script>

<style scoped lang="less">
@import "../style/index.less";
</style>
