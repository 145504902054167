<template>
  <div class="c">
    <el-form
      :label-position="labelPosition"
      label-width="120px"
      :model="obj"
      :rules="rules"
      ref="form"
    >
      <div class="c-top">
        <el-button
          size="mini"
          type="primary"
          @click="changeEdit"
          v-if="(!isEdit && eInfo.isEdit !== '1') || (!editOfficialSeal && eInfo.contactPersonFlag === '1')"
          >编辑
        </el-button>
      </div>
      <div class="c-title">
        <div class="c-t-span">基础信息</div>
      </div>
      <el-row>
        <el-col :span="6">
          <el-form-item label="公司编号" prop="enterpriseCode">
            <el-input
              placeholder="请输入"
              v-model="obj.enterpriseCode"
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="公司类型" prop="enterpriseType">
            <tree-select
              style="flex: 1"
              placeholder="请选择类型"
              :clearable="false"
              :options="companyType"
              v-model="obj.enterpriseType"
              :disable-branch-nodes="true"
              :searchable="true"
              :defaultExpandLevel="10"
              :disabled="!isEdit || isFounder"
              :key="obj.enterpriseType + '_' + companyType.length"
              :flatten-search-results="true"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="网址:" prop="website">
            <el-input
              placeholder="请输入"
              v-model="obj.website"
              :disabled="!isEdit ||isFounder"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="传真:" prop="fax">
            <el-input
              placeholder="请输入"
              v-model="obj.fax"
              :disabled="!isEdit || isFounder"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="办公区域" prop="areas">
            <el-cascader
              v-model="obj.areas"
              :options="areaInfo"
              :props="{ expandTrigger: 'hover' }"
              style="width: 100%"
              :disabled="!isEdit || isFounder"
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item label="详细地址:" prop="address">
            <el-input
              placeholder="请输入"
              v-model="obj.address"
              type="textarea"
              :disabled="!isEdit || isFounder"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="c-row">
        <el-form-item label="营业执照:">
          <upload-file
            isType="add"
            :disabled="!isEdit || isFounder"
            list-type="picture-card"
            v-model="obj.enterpriseLicense"
            :limit="1"
            :list="eInfo.enterpriseLicense"
          />
        </el-form-item>
      </div>
	  <div class="c-row">
	    <el-form-item label="公司合同章:">
	      <upload-file
	        isType="add"
	        :disabled="!editOfficialSeal"
	        list-type="picture-card"
	        v-model="obj.ids"
	        :limit="1"
	        :list="eInfo.sealFlies"
	      />
	    </el-form-item>
	  </div>
      <el-row>
        <el-col :span="7">
          <el-form-item label="员工人数:" prop="staffNum">
            <el-select
              placeholder="请选择"
              v-model="obj.staffNum"
              :disabled="!isEdit || isFounder"
            >
              <el-option
                v-for="item in staffNum"
                :key="item.type"
                :label="item.title"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="年营业额:" prop="annualTurnover">
            <el-select
              placeholder="请选择"
              v-model="obj.annualTurnover"
              :disabled="!isEdit || isFounder"
            >
              <el-option
                v-for="item in annualTurnover"
                :key="item.type"
                :label="item.title"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="付款方式:" prop="payStatus">
            <el-select
              placeholder="请选择"
              v-model="obj.payStatus"
              :disabled="!isEdit || isFounder"
            >
              <el-option
                v-for="item in paymentTypes"
                :key="item.type"
                :label="item.title"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="是否加企业微信:" prop="isWechatFriend">
            <el-select
              placeholder="请选择"
              v-model="obj.isWechatFriend"
              :disabled="!isEdit || isFounder"
            >
              <el-option
                v-for="item in isWechatFriend"
                :key="item.type"
                :label="item.title"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="企业状态">
            <el-input
              v-model="obj.enterpriseOcrStatus"
              placeholder="请输入"
              :disabled="!isEdit || isFounder"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="企业注册号">
            <el-input
              v-model="obj.enterpriseRegisteredCode"
              placeholder="请输入"
              :disabled="!isEdit || isFounder"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="企业统一社会信用编码">
            <el-input
              v-model="obj.socialCreditCode"
              placeholder="请输入"
              :disabled="!isEdit || isFounder"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="企业所在地国家行政编码">
            <el-input
              v-model="obj.enterpriseNationalCode"
              placeholder="请输入"
              :disabled="!isEdit || isFounder"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="企业法人">
            <el-input
              v-model="obj.legalPersonName"
              placeholder="请输入"
              :disabled="!isEdit || isFounder"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="企业注册资本">
            <el-input
              v-model="obj.registeredCapital"
              placeholder="请输入"
              :disabled="!isEdit || isFounder"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="企业注册时间">
            <el-input
              v-model="obj.establishedTime"
              placeholder="请输入"
              :disabled="!isEdit || isFounder"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="企业类型">
            <el-input
              v-model="obj.enterpriseOcrType"
              placeholder="请输入"
              :disabled="!isEdit || isFounder"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="企业营业期限开始时间">
            <el-input
              v-model="obj.startBusinessTime"
              placeholder="请输入"
              :disabled="!isEdit || isFounder"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="企业营业期限结束时间">
            <el-input
              v-model="obj.endBusinessTime"
              placeholder="请输入"
              :disabled="!isEdit || isFounder"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="企业注册地址">
            <el-input
              v-model="obj.enterpriseAddr"
              placeholder="请输入"
              :disabled="!isEdit || isFounder"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="企业税务登记号">
            <el-input
              v-model="obj.taxRegisteredCertificate"
              placeholder="请输入"
              :disabled="!isEdit || isFounder"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="企业组织机构代号">
            <el-input
              v-model="obj.organizationCode"
              placeholder="请输入"
              :disabled="!isEdit || isFounder"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="c-footer" v-if="isEdit || editOfficialSeal">
      <el-button
        type="primary"
        size="mini"
        style="margin-right: 20px"
        @click="confirm"
        >确认</el-button
      >
      <el-button size="mini" style="margin-left: 20px" @click="cancel"
        >取消</el-button
      >
    </div>
  </div>
</template>

<script>
import request from "@/common/js/request.js";
import api from "@/common/js/api.js";
import { mapState, mapActions } from "vuex";
import uploadFile from "@/components/publicComponent/uploadFile.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import area from "@/common/json/area.json";
import { handleTreeData } from "@/common/js/common.js";
import login from "@/module/loginModule/viewPage/login";
export default {
  data() {
    return {
      isEdit: false, //是否编辑
      isFounder: false, // 是否创始人
      editOfficialSeal: false, // 是否创始人 仅创始人可修改公章，且只能修改公章
      areaInfo: handleTreeData(area, "name", "name"),
      obj: {
        //表单信息
        enterpriseCode: null, //wyb  企业编码
        enterpriseType: null, //企业类型
        website: "", //网址
        fax: "", //传值
        address: "", //企业地址
        enterpriseLicense: [], //营业执照
        staffNum: "", //员工人数
        annualTurnover: "", //年营业额
        competitor: "", //竞争对手
        payStatus: "", //付款方式
        creditLimit: "", //信用额度
        isWechatFriend: "", //是否加企业微信
        areas: [],
        enterpriseOcrStatus: "",
        endBusinessTime: "",
        enterpriseAddr: "",
        enterpriseCity: "",
        enterpriseDistrict: "",
        enterpriseName: "",
        enterpriseProvince: "",
        enterpriseOcrType: "",
        establishedTime: "",
        legalPersonName: "",
        organizationCode: "",
        registeredCapital: "",
        socialCreditCode: "",
        startBusinessTime: "",
        taxRegisteredCertificate: "",
        enterpriseRegisteredCode: "",
        enterpriseNationalCode: "",
		ids:[],
      },
      eInfo: {}, //企业信息
      companyType: [], //企业类型数据
      // rules: {
      //   enterpriseCode: [
      //     {
      //       required: true,
      //       message: "请输入公司编号",
      //       trigger: "blur",
      //     },
      //   ],
      //   enterpriseType: [
      //     {
      //       required: true,
      //       message: "请选择公司类型",
      //       trigger: "change",
      //     },
      //   ],
      //   website: [
      //     {
      //       required: true,
      //       message: "请输入网址",
      //       trigger: "blur",
      //     },
      //   ],
      //   fax: [
      //     {
      //       required: true,
      //       message: "请输入传真",
      //       trigger: "blur",
      //     },
      //   ],
      //   address: [
      //     {
      //       required: true,
      //       message: "请输入办公地址",
      //       trigger: "blur",
      //     },
      //   ],
      //   enterpriseLicense: [
      //     {
      //       required: true,
      //       message: "请选择营业执照",
      //       trigger: "change",
      //     },
      //   ],
      //   staffNum: [
      //     {
      //       required: true,
      //       message: "请选择员工人数",
      //       trigger: "change",
      //     },
      //   ],
      //   annualTurnover: [
      //     {
      //       required: true,
      //       message: "请选择年营业额",
      //       trigger: "change",
      //     },
      //   ],
      //   competitor: [
      //     {
      //       required: true,
      //       message: "请输入竞争对手",
      //       trigger: "blur",
      //     },
      //   ],
      //   payStatus: [
      //     {
      //       required: true,
      //       message: "请选择付款方式",
      //       trigger: "change",
      //     },
      //   ],
      //   creditLimit: [
      //     {
      //       required: true,
      //       message: "请输入信用额度",
      //       trigger: "blur",
      //     },
      //   ],
      //   isWechatFriend: [
      //     {
      //       required: true,
      //       message: "请选择是否加企业微信",
      //       trigger: "change",
      //     },
      //   ],
      // },
    };
  },
  props: ["info"],
  components: {
    uploadFile,
  },
  computed: {
    ...mapState("purchaseMudule", [
      "paymentTypes", //付款方式
    ]),
    ...mapState([
      "token", //获取token
    ]),
    ...mapState("myMessageModule", [
      "isWechatFriend", //是否加企业微信
      "staffNum", //员工人数
      "annualTurnover",
    ]),
    getAction() {
      //上传文件地址
      return request.defaults.baseURL + api.uploadFile + "?fileType=CUSTOMER";
    },
    getHeaders() {
      //请求头部
      return {
        Authorization: this.token || null,
      };
    },
  },
  methods: {
    ...mapActions("myMessageModule", [
      "editEnterprise", //修改企业
      "enterpriseTreeList", //获取企业类型列表
      "getEnterprise", //获取企业详情
    ]),
    handleCheckChange(a, b) {
      this.$refs["tree"].setCheckedKeys([a.label], b);
    },
    handleTreeData(data, type, ceng) {
      //处理数据
      ceng = ceng || 0;
      ceng++;
      data.map((item) => {
        item.disabled = ceng === 1 ? true : type;
        item.id = item.id;
        item.label = item.categoryName;
        if (item.children && item.children.length) {
          item.children = this.handleTreeData(item.children, type, ceng);
        } else {
          delete item.children;
        }
      });
      return data;
    },
    changeEdit() {
      //编辑的点击事件
      console.log(this.isFounder, '---')
      if (this.isFounder) {
        this.editOfficialSeal = true;
      } else {
        this.isEdit = true;
      }
      this.companyType = this.handleTreeData(this.companyType, false);
    },
    cancel() {
      if (this.isFounder) {
        this.editOfficialSeal = false;
      } else {
        this.isEdit = false;
      }
      this.companyType = this.handleTreeData(this.companyType, true);
    },
    confirm() {
      //确认的点击事件
      this.$refs["form"].validate((valid) => {
        if (valid) {
          ElMessageBox.confirm("个人信息只能修改一次，是否确认修改?", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              var data = {
                ...this.eInfo,
                ...this.obj,
              };
              data.enterpriseLicense = data.enterpriseLicense[0];
              data.province = data.areas[0];
              data.city = data.areas[1];
              data.district = data.areas[2];
			  data.sealFlies = data.ids.length ? data.ids[0] : null;
              delete data.areas;
              this.editEnterprise(data).then((res) => {
                let { code, data } = res.data;
                if (code === "0") {
                  ElMessage({
                    type: "success",
                    message: "修改成功",
                  });
                  this.init(); //更新详情
                  this.cancel();
                }
              });
            })
            .catch(() => {});
        }
      });
    },
    getTypes() {
      //判断企业类型
      this.enterpriseTreeList({}).then((res) => {
        let { code, data } = res.data;
        if (code === "0") {
          this.companyType = this.handleTreeData(data, true);
        }
      });
    },
    init() {
      console.log(this.info, '-----info')
      if (this.info && this.info.enterpriseId) {
        this.getEnterprise(this.info.enterpriseId).then((res) => {
          let { code, data } = res.data;
          // 是否创始人 仅创始人可修改公章，且只能修改公章
          if (data && data.contactPersonFlag === '1') {
            this.isFounder = true
          }
          if (code === "0") {
            var obj = JSON.parse(JSON.stringify(this.obj));
            data.enterpriseLicense = JSON.parse(data.enterpriseLicense || "[]");
			data.sealFlies = JSON.parse(data.sealFlies || "[]");
            if (data.province) {
              data.areas = [data.province, data.city, data.district]; //回显省市区
            }
            data.enterpriseId = data.id;
            for (var k in obj) {
              if (data[k] && k !== "enterpriseLicense") {
                obj[k] = data[k];
              }
            }
            this.eInfo = data; //企业信息
            this.obj = obj; //回显信息
          }
        });
      }
    },
  },
  created() {
    this.getTypes();
    this.init();
  },
  watch: {
    info(v) {
      this.init();
    },
  },
};
</script>

<style scoped lang="less">
@import "../style/index.less";

/deep/ .el-form-item__label {
  white-space: nowrap;
  overflow: hidden;
}

/deep/ .el-form-item__content {
  display: flex;
  align-items: center;
}
</style>
