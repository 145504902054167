<template>
	<el-dialog 
		width="600px" 
		:before-close="handleClose"
		:destroy-on-close="true"
		@open="open"
		@close="close"
		>
		<div class="a-main">
			<div class="a-title">新增归属地</div>
			<el-form 
				ref="ruleForm"
				:model="ruleForm"
				:rules="rules"
				label-width="100px"
			>
				<el-form-item label="归属地:" prop="value">
					<el-cascader
					      v-model="ruleForm.value"
					      :options="areaInfo"
					      :props="{ expandTrigger: 'hover' }"
					      @change="handleChange"
						  style="width: 100%;"
						  :disabled="isEdit"
					></el-cascader>
				</el-form-item>
				<el-form-item label="详细地址:" prop="address">
					<el-input v-model="ruleForm.address" placeholder="请输入详细地址" :disabled="isEdit"></el-input>
				</el-form-item>
				<el-form-item label="收货人:" prop="consigneeName">
					<el-input v-model="ruleForm.consigneeName" placeholder="请输入收货人" :disabled="isEdit"></el-input>
				</el-form-item>
				<el-form-item label="收货人电话:" prop="consigneePhone">
					<el-input v-model="ruleForm.consigneePhone" placeholder="请输入收货人电话" :disabled="isEdit"></el-input>
				</el-form-item>
				<el-form-item label="备注:">
					<el-input :rows="4" type="textarea" v-model="ruleForm.remarks" :disabled="isEdit"></el-input>
				</el-form-item>
				<el-form-item label="默认地址:">
					<el-radio-group v-model="ruleForm.defaultFlag" :disabled="isEdit">
					    <el-radio label="0">否</el-radio>
					    <el-radio label="1">是</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<div class="a-footer">
				<el-button @click="cancel" size="mini">取消</el-button>
				<el-button @click="confirm" type="primary" size="mini">确认</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import area from "@/common/json/area.json"
	import { handleTreeData } from "@/common/js/common.js"
	import { mapActions } from "vuex"
	import { ElMessage } from "element-plus"
	export default {
		data() {
			return {
				show: false, //弹框的隐藏显示
				areaInfo:handleTreeData(area,'name','name'),
				ruleForm:{  //表单数据
					value:'',  //地址数据
					address:'',  //详细地址
					consigneeName:'',  //收货人
					consigneePhone:'',  //收货人电话
					remarks:'',  //备注
					defaultFlag:'0',  //是否是收货地址
				},
				rules:{  //验证数据
					value:[
						{required: true,message: '请选择归属地',trigger: 'change'}
					],
					address:[
						{required: true,message: '请输入详细地址',trigger: 'blur'}
					],
					consigneeName:[
						{required: true,message: '请输入收货人',trigger: 'blur'}
					],
					consigneePhone:[
						{required: true,message: '请输入收货人电话',trigger: 'blur'}
					]
				}
			}
		},
		props: {
			type:{
				type:String,
				default:'add',  //add新增地址 edit修改地址 address地址详情
			},
			uid:{
				type:String,
				default:'',
			}
		},
		methods: {
			...mapActions('myMessageModule',[
				'getUserConsignee',  //地址详情
				'editUserConsignee',  //修改地址
				'addUserConsignee',  //新增收货地址
			]),
			cancel(){
				this.$emit('update:modelValue', false);
			},
			confirm(val){ //确认的点击事件
				if(this.type === "add" || this.type === "edit"){ //新增或修改
					this.$refs['ruleForm'].validate((valid) => {
						if(valid){
							var data = JSON.parse(JSON.stringify(this.ruleForm));
							data.province = data.value[0];  //省
							data.city = data.value[1];  //市
							data.district = data.value[2]; //区
							delete data.value;
							if(this.type === "add"){
								this.operation(this.addUserConsignee,data,'新增成功');
							}else if(this.type === 'edit'){
								this.operation(this.editUserConsignee,data,'修改成功');
							}
						}
					})
				}else{
					this.cancel();
				}
			},
			operation(fn,data,text){
				fn(data).then(res => {
					let {code,data} = res.data;
					if(code === "0"){
						ElMessage({
							type:'success',
							message:text,
						})
						this.cancel();
						this.$emit("success");
					}
				})
			},
			open(){
				if(this.type === 'edit' || this.type === 'address'){
					//获取详情信息
					this.getUserConsignee(this.uid).then(res => {
						let {code,data} = res.data;
						if(code === "0"){
							data.value = [
								data.province,
								data.city,
								data.district
							]
							this.ruleForm = data;
						}
					})
				}
			},
			close(){ //关闭的回调
				this.ruleForm = {  //表单数据
					value:'',  //地址数据
					address:'',  //详细地址
					consigneeName:'',  //收货人
					consigneePhone:'',  //收货人电话
					remarks:'',  //备注
					defaultFlag:'0',  //是否是收货地址
				};   //清空数据
			}
		},
		created(){
			
		},
		computed:{
			isEdit(){
				return this.type === "address";
			}
		},
	}
</script>

<style scoped lang="less">
	.a-main{
		padding: 20px 60px 20px 20px;
		box-sizing: border-box;
		.a-title{
			font-size: 14px;
			font-weight: bold;
			padding-bottom: 20px;
		}
		.a-footer{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 160px;
		}
	}
</style>
