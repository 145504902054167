<template>
	<div class="c">
		<div class="c-top">
			<el-button
				size="mini"
				type="primary"
				@click="changeEdit"
				v-if="!isEdit">编辑</el-button>
		</div>
		<div class="c-title">
			<div class="c-t-span">开票信息</div>
		</div>
		<el-form
		    label-width="100px"
		    :model="info"
			:rules="rules"
			ref="form"
		  >
			<el-row>
				<el-col :span="7">
					<el-form-item label="公司名称:" prop="invoiceHead">
					  <el-input v-model="info.invoiceHead" :disabled="!isEdit"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="7">
					<el-form-item label="开票电话:" prop="invoicePhone">
					  <el-input v-model="info.invoicePhone" :disabled="!isEdit"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="7">
					<el-form-item label="行号:" >
					  <el-input v-model="info.bankNumber" :disabled="!isEdit"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="7">
					<el-form-item label="公司地址:" prop="address">
					  <el-input v-model="info.address" :disabled="!isEdit"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="7">
					<el-form-item label="开户行:" prop="bankName">
					  <el-input v-model="info.bankName" :disabled="!isEdit"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="7">
					<el-form-item label="公司税号:" prop="taxpayerCode">
					  <el-input v-model="info.taxpayerCode" :disabled="!isEdit"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="7">
					<el-form-item label="银行账号:" prop="bankAccount">
					  <el-input v-model="info.bankAccount" :disabled="!isEdit"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<div class="c-footer" v-if="isEdit">
			<el-button type="primary" size="mini" style="margin-right: 20px;" @click="confirm">确认</el-button>
			<el-button size="mini" style="margin-left: 20px;" @click="cancel">取消</el-button>
		</div>
	</div>
</template>

<script>
	import { mapActions } from 'vuex'
	import { ElMessage } from "element-plus"
	export default{
		data(){
			return{
				isEdit:false,  //是否可编辑
				info:{
					invoiceHead:'',
					invoicePhone:'',
					bankNumber:'',
					address:'',
					bankName:'',
					taxpayerCode:'',
					bankAccount:'',
				},
				rules:{
					invoiceHead:[
						{required:true,message:'请输入公司名称',trigger: 'blur'}
					],
					invoicePhone:[
						{required:true,message:'请输入开票电话',trigger: 'blur'}
					],
					// bankNumber:[
					// 	{required:true,message:'请输入行号',trigger:'blur'}
					// ],
					address:[
						{required:true,message:'请输入公司地址',trigger:'blur'}
					],
					bankName:[
						{required:true,message:'请输入开户行',trigger:'blur'}
					],
					taxpayerCode:[
						{required:true,message:'请输入公司税号',trigger:'blur'}
					],
					bankAccount:[
						{required:true,message:'请输入银行账号',trigger:'blur'}
					]
				}
			}
		},
		methods:{
			...mapActions('myMessageModule',[
				'userGetUserInvoice',  //获取发票信息
				'userInvoiceEdit',  //修改发票信息
			]),
			changeEdit(){ //编辑信息
				this.isEdit = true;
			},
			cancel(){
				this.isEdit = false;
			},
			confirm(){ //确认的点击事件
				this.$refs['form'].validate((valid) => {
					if(valid){
						this.userInvoiceEdit(this.info).then(res => {
							let {code,data} = res.data;
							if(code === '0'){
								ElMessage({
									type:'success',
									message:'编辑成功'
								})
								this.getInfo();  //刷新详情信息
							}
						})
					}
				})
			},
			getInfo(){ //获取发票信息
				this.userGetUserInvoice({}).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						this.info = data;
					}
				})
			}
		},
		created(){
			this.getInfo();
		}
	}
</script>

<style scoped lang="less">
	@import "../style/index.less";
</style>
