<template>
	<div class="c">
		<div class="c-top" style="padding-bottom: 10px;">
			<el-button
				size="mini" 
				type="primary" 
				@click="addGoods">新增</el-button>
		</div>
		<lida-table
			:cutHeight="0" 
			:data="data" 
			border 
			:page="page"
			:pageSize="pageSize"
			@change="pageChange"
			:total="total"
			ref="table">
			<el-table-column type="index" label="序号" width="55" />
			<lida-table-column label="品牌名称">
				<template #default="scope">
					<div>{{scope.row.brandName}}</div>
				</template>
			</lida-table-column>
			<lida-table-column label="操作" width="50">
				<template #default="scope">
					<div @click="deleteRow(scope.row)" class="row-delete">删除</div>
				</template>
			</lida-table-column>
		</lida-table>
	</div>
	
	<!-- 添加商品弹框 -->
	<add-brand-dialog 
		@success="addSuccess"
		v-model="show" />
	
</template>

<script>
	import addBrandDialog from './dialog/addBrand.vue'
	import { mapActions } from 'vuex'
	import { ElMessageBox, ElMessage } from 'element-plus'
	export default{
		data(){
			return{
				show:false,
				page:1,
				pageSize:10,
				total:0,
				data:[],  //列表数据
			}
		},
		components:{
			addBrandDialog,
		},
		methods:{
			...mapActions('myMessageModule',[
				'userPageEmption',  //获取我的买卖商品
				'userDelEmption',  //删除我的买卖商品
			]),
			deleteRow(row){ //删除行
				console.log(row);
				ElMessageBox.confirm('是否删除该品牌?','提示',{
				  confirmButtonText: '确认',
				  cancelButtonText: '取消',
				  type: 'warning',
				}).then(() => {
				  this.userDelEmption(row.id).then(res => {
					  let {code,data} = res.data;
					  if(code === '0'){
						  ElMessage({type: 'success',message: '删除成功',})
						  this.getList();  //获取列表
					  }
				  })
				}).catch(() => {})
			},
			addGoods(){ //添加商品
				this.show = true;
			},
			addSuccess(data){ //添加成功的回调
				this.getList();
			},
			getList(){
				this.userPageEmption({
					pageNo:this.page,
					pageSize:this.pageSize,
					type:'0',
				}).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						this.data = data.rows;
						this.total = data.totalCount;
					}
				})
			},
			pageChange(page,pageSize){ //获取列表数据
				this.page = page;
				this.pageSize = pageSize;
				this.getList();
			}
		},
		created(){
			this.getList();
		}
	}
</script>

<style scoped lang="less">
	@import "../style/index.less";
</style>
