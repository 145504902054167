<template>
	<div class="ra c">
		<div class="ra-title">
			<el-button type="primary" @click="addModule" size="mini">新增</el-button>
		</div>
		<div class="ra-content">
			<div class="ra-list" v-for="(item,index) in list" :key="index">
				<div class="ra-row">
					<div class="ra-col">
						{{item.consigneeName}}（{{item.consigneePhone}}）
						<span>
							{{item.province}}-{{item.city}}-{{item.district}} {{item.address}}
						</span>
						<el-tag 
							effect="dark" 
							v-if="item.defaultFlag === '1'" 
							style="margin-left: 10px;"
							size="mini">默认</el-tag>
					</div>
					<div class="ra-right">
						<div class="ra-c-btn" @click="operaRow('编辑',item)">
							<span>编辑</span><i class="iconfont icon-bianjishuru-xianxing"></i>
						</div>
						<div class="ra-c-btn" @click="operaRow('查看',item)">
							<span>查看</span><i class="iconfont icon-chakan"></i>
						</div>
						<div class="ra-c-btn" @click="operaRow('删除',item)">
							<span>删除</span><i class="iconfont icon-shanchu"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- 新增弹框 -->
	<add-address 
		:type="operaType" 
		@success="addSuccess" 
		:uid="activeRow?activeRow.id:null"
		v-model="show"/>
</template>

<script>
	import { mapActions } from 'vuex'
	import addAddress from "./dialog/addAddress.vue"  //新增地址弹框
	import { ElMessageBox, ElMessage } from 'element-plus'
	export default{
		data(){
			return{
				show:false,  //弹框的隐藏显示
				list:[],  //收货地址列表
				operaType:'add',
				activeRow:null,
			}
		},
		components:{
			addAddress
		},
		methods:{
			...mapActions('myMessageModule',[
				'userConsigneeList',  //获取地址列表
				'deleteUserConsignee',  //删除地址
			]),
			addModule(){
				this.operaType = 'add';
				this.show = true;
			},
			getList(){
				this.userConsigneeList().then(res => {
					console.log(res);
					let {code,data} = res.data;
					if(code === '0'){
						this.list = data;
					}
				})
			},
			addSuccess(){ //添加成功的回调
				this.getList();
			},
			operaRow(type,row){ //删除的点击事件
				this.activeRow = row;
				switch(type){
					case '查看':
						this.operaType = 'address';
						this.show = true;
					break;
					case '删除':
						 ElMessageBox.confirm('是否删除该地址信息?', '提示', {
							confirmButtonText: '确认',
							cancelButtonText: '取消',
							type: 'warning',
						}).then(() => {
							this.deleteUserConsignee(row.id).then(res => {
								let {code,data} = res.data;
								if(code === '0'){
									ElMessage({
										type:'success',
										message:'删除成功'
									})
									//刷新页面数据
									this.getList();
								}
							})
						})
					break;
					case '编辑':
						this.operaType = 'edit';
						this.show = true;
					break;
				}
			}
		},
		created(){
			this.getList();  //获取地址列表
		}
	}
</script>

<style scoped lang="less">
	@import "../style/index.less";
	.ra{
		.ra-title{
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-bottom: 10px;
			height: 30px;
		}
		.ra-list{
			border: 1px solid #e9e9e9;
			padding: 10px;
			box-sizing: border-box;
			margin-bottom: 15px;
		}
		.ra-list:last-of-type{
			margin-bottom: 0;
		}
		.ra-row{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.ra-right{
				display: none;
				align-items: center;
				color: #666666;
				cursor: pointer;
				.ra-c-btn{
					margin-left: 20px;
					span{
						margin-right: 4px;
					}
				}
			}
		}
		.ra-row:hover{
			.ra-right{
				display: flex;
			}
		}
	}
</style>
