<template>
	<el-dialog
	    width="600px"
		:destroy-on-close="true"
	  >
		<div class="a">
			<div class="a-title">添加品牌</div>
			<div class="a-content">
				<el-form
				    label-width="100px"
				    :model="obj"
					:rules="rules"
					ref="form"
				  >
				    <el-form-item label="品牌" prop="brandIds">
						<el-select 
							v-model="obj.brandIds" 
							multiple 
							placeholder="请选择品牌">
							<el-option
							  v-for="item in data"
							  :key="item.id"
							  :label="item.categoryName"
							  :value="item.id"
							>
							</el-option>
						</el-select>
				    </el-form-item>
				</el-form>
				
			</div>
			<div class="a-footer">
				<el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
				<el-button style="margin-left: 20px;" type="primary" size="mini" @click="confirm">确认</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import { mapActions } from 'vuex'
	import { ElMessage } from "element-plus"
	export default{
		data(){
			return{
				obj:{
					brandIds:[],
					type:0,
				},
				data:[],
				rules:{
					brandIds:[
						{required: true,message: '请选择品牌',trigger: 'change'},
					]
				}
			}
		},
		methods:{
			...mapActions('myMessageModule',[
				'listSkuCategoryForFirst',  //获取品牌列表
				'userCreateEmption',  //我的买卖新增
			]),
			close(){ //关闭弹框
				this.$emit('update:modelValue', false);
			},
			confirm(){ //确认的点击事件
				this.$refs.form.validate((valid) => {
					if(valid){
						this.userCreateEmption(this.obj).then(res => {
							let {code,data} = res.data;
							if(code === '0'){
								ElMessage({
									type:'success',
									message:'新增成功'
								})
								this.$emit('success');
								this.close();
							}
						})
					}
				})
			},
			getList(){ //请求品牌列表
				this.listSkuCategoryForFirst({
					pageNo:1,
					pageSize:9999
				}).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						this.data = data.data;
					}
				})
			}
		},
		created(){
			this.getList();
		}
	}
</script>

<style scoped lang="less">
	.a{
		padding: 20px;
		.a-title{
			padding-bottom: 20px;
			font-weight: bold;
		}
		.a-footer{
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 20px;
		}
	}
</style>
