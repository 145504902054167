<template>
  <div class="c">
    <div class="c-top" style="padding-bottom: 10px;">
    </div>
    <lida-table
      :cutHeight="0"
      :data="data"
      border
      :page="page"
      :pageSize="pageSize"
      @change="pageChange"
      :total="total"
      ref="table"
    >
      <el-table-column type="index" label="序号" width="55" />
      <lida-table-column label="姓名" prop="realName" />
     
      <lida-table-column label="手机号码" prop="mobilePhone"/>
      <lida-table-column label="所属公司名称" prop="enterpriseName"/>
      <lida-table-column label="操作" width="180">
        <template #default="scope">
          <!-- <div @click="deleteRow(scope.row)" class="row-delete">删除</div> -->
          <div @click="changeRow(scope.row)" class="row-delete">转移公司主账号</div>
        </template>
      </lida-table-column>
    </lida-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  props: ["info"],
  data() {
    return {
      show: false,
      page: 1,
      pageSize: 10,
      total: 0,
      data: [], //列表数据
    };
  },
  methods: {
    ...mapActions("myMessageModule", [
      'platformUserListByEnterpriseId',//查看企业下的员工
      'updateCustomerIdForEnterprise',//企业-主账号转移(所属人)
    ]),
    changeRow(row){ //转移主账号
    	console.log(row);
    	ElMessageBox.confirm('是否转移主账号?','提示',{
    	  confirmButtonText: '确认',
    	  cancelButtonText: '取消',
    	  type: 'warning',
    	}).then(() => {
    	  this.updateCustomerIdForEnterprise({customerId :row.id,enterpriseId:row.enterpriseId,enterpriseName:row.enterpriseName }).then(res => {
    		  let {code,data} = res.data;
    		  if(code === '0'){
    			  ElMessage({type: 'success',message: '转移成功',})
    			  this.getList();  //获取列表
    		  }
    	  })
    	}).catch(() => {})
    },
    getList() {
    //   console.log(this.info, "-----info");
      if (this.info && this.info.enterpriseId) {
        this.platformUserListByEnterpriseId(this.info.enterpriseId).then(res=>{
            let { code, data } = res.data;
           if(code=='0'){
            this.data=[...data]
            // this.total = data.totalCount;
            this.total=data.length
           }
        })
      }
      // this.userPageEmption({
      // 	pageNo:this.page,
      // 	pageSize:this.pageSize,
      // 	type:'0',
      // }).then(res => {
      // 	let {code,data} = res.data;
      // 	if(code === '0'){
      // 		this.data = data.rows;
      // 		this.total = data.totalCount;
      // 	}
      // })
    },
    pageChange(page, pageSize) {
      //获取列表数据
      this.page = page;
      this.pageSize = pageSize;
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style scoped lang="less">
@import "../style/index.less";
</style>
